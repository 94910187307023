<template>
  <main id="favorites">
    <bar-breadcrumbs :data="breadcrumbs" />
    <section class="bar__categories">
      <div class="container">
        <div class="flex flex-wrap">
          <div class="w-full px-4 relative">
            <header-h3
              :content="$t('components.favorites.header')"
              align="center"
              casing="uppercase"
              mt="0"
              mb="0" />
            <template v-if="!loggedIn">
              <div class="my-4 bg-orange text-white px-4 py-2">
                {{ $t('components.favorites.notLoggedIn') }}
              </div>
              <div class="form-group w-full tablet:w-4/12 tablet:mx-auto mt-10">
                <button-primary
                  :text="$t('components.account.login')"
                  :link="localePath('account')"
                  type="link"
                  extra-class="block"
                />
              </div>
            </template>
            <template v-else>
              <div
                v-if="!loaded"
                class="h-full w-full flex flex-col items-center justify-center bg-white bg-opacity-75 inset-0 z-50 absolute">
                <p class="font-bold uppercase text-xl mb-4">Loading...</p>
                <img src="~/assets/images/loading.svg">
              </div>
              <div
                v-if="!favoritesList || favoritesList.length === 0"
                class="my-4 bg-orange text-white px-4 py-2">
                {{ $t('components.favorites.empty') }}
              </div>
              <div v-else>
                <div class="flex flex-wrap -mx-4">
                  <div class="w-full px-4 mt-6">
                    <table class="table">
                      <thead>
                        <tr>
                          <td class="no_mobile_col">
                            {{ $t('components.cart.headers.image') }}
                          </td>
                          <td>
                            {{ $t('components.cart.headers.product_name') }}
                          </td>
                          <td class="price_column">
                            {{ $t('components.cart.headers.price') }}
                          </td>
                          <td style="width: 20px;"/>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(favoriteItem, index) in favoritesList">
                          <td class="no_mobile_col">
                            <nuxt-link
                              :to="localePath(favoriteItem.link)">
                              <img
                                :src="favoriteItem.image"
                                :alt="favoriteItem.name"
                                class="w-32" />
                            </nuxt-link>
                          </td>
                          <td>
                            <nuxt-link
                              :to="localePath(favoriteItem.link)"
                              v-html="favoriteItem.name" />
                            <br>
                            <small>
                              <strong v-t="'components.products.sku'" />
                              <span v-html="favoriteItem.sku" />
                            </small>
                          </td>
                          <td>
                            {{ favoriteItem.displayPrice }}
                          </td>
                          <td class="text-center">
                            <button
                              @click="removeItemFromFavorites(favoriteItem.product_id)"
                              type="button">
                              X
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="flex">
                  <button
                    @click="clearFavorites"
                    class="button button--tertiary ml-auto mt-6">
                    {{ $t('components.favorites.empty_favorites') }}
                  </button>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import BarBreadcrumbs from '../components/bars/Breadcrumbs';
import portal from '../service/portal';
import CommonUtils from '/helpers/CommonUtils';

export default {
  name: 'Favorites',
  components: {
    BarBreadcrumbs,
  },
  computed: {
    favoritesList () {
      let outputList = [];

      for (let i = 0; i < this.favorites.length; i++) {
        let currentItem = this.favorites[i];
        let favoriteItem = {
          id: currentItem.id,
          product_id: currentItem.product_id,
          user_id: currentItem.user_id,
          sku: currentItem.product.sku
        }

        favoriteItem.image = (currentItem.product.images && currentItem.product.images.length > 0)
          ? currentItem.product.images[0]
          : '/images/placeholder.jpg';


        let translationForLanguage = currentItem.product.translations.find(item => item.language.language_code === this.$store.state.language);
        favoriteItem.name = '';
        if (translationForLanguage && translationForLanguage.name) {
          favoriteItem.name = translationForLanguage.name
        }

        favoriteItem.link = this.$slugify(`${currentItem.product.id} ${currentItem.product.sku} ${favoriteItem.name}`);

        favoriteItem.link = {
          name: 'product-product',
          params: {
            product: this.$slugify(`${currentItem.product.id} ${currentItem.product.sku} ${favoriteItem.name}`)
          }
        }

        let price = 0;
        let stock = 0;

        if (currentItem.product.price_object) {
          const priceObject = JSON.parse(currentItem.product.price_object.price_object);
          const priceList = this.$store.state.priceList;
          price = priceObject['Pricelist' + priceList];
          stock = priceObject.stocklevel;
        }

        favoriteItem.price = price || 0;
        favoriteItem.stock = stock;

        favoriteItem.displayPrice = this.getPriceFormatted(parseFloat(favoriteItem.price));

        outputList.push(favoriteItem);
      }

      return outputList;
    },
  },
  data() {
    return {
      locale: this.$i18n.locale,
      loggedIn: this.$auth.loggedIn,
      favorites: [],
      loaded: false,
      currency: 'EUR',
      breadcrumbs: [
        {
          name: this.$t('menu.home'),
          url: 'index'
        },
        {
          name: this.$t('menu.favorites'),
          url: 'favourites'
        }
      ]
    }
  },
  head() {
    const title = this.$i18n.t('components.favorites.header') + ' - Air Conditioning Parts - AP Air Ltd.'
    const description = ''

    return {
      title,
      titleTemplate: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        { property: 'og:locale', content: 'en_GB' },
        { property: 'og:type', content: 'page' },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        {
          property: 'og:url',
          content: 'https://www.apairltd.com' + this.$route.fullPath
        },
        { property: 'og:site_name', content: 'AP Air Ltd.' }
      ]
    }
  },
  mounted() {
    const priceList = this.$store.state.priceList;
    this.currency = CommonUtils.getCurrencyForPriceList(priceList);

    if (!this.loggedIn) {
      this.loaded = true;
      return;
    }

    this.loadFavorites();
  },
  methods: {
    loadFavorites () {
      this.loaded = false;

      portal.get(`favourites/get-all/${this.$auth.user.user_id}`)
      .then((response) => {
        this.favorites = response.data.products;
        this.loaded = true;
        this.$store.commit('SET_FAVORITE_ITEMS', this.favorites.map(item => item.product_id));
        this.$store.commit('SET_FAVORITE_ITEMS_FETCHED', true);
      });
    },
    removeItemFromFavorites (productID) {
      let requestParams = {
        user_id: this.$auth.user.user_id,
        product_id: productID
      };

      let headers = {
        'Content-Type': 'application/json'
      }

      this.loaded = false;

      portal.post(`favourites/remove`, requestParams, headers)
      .then((response) => {
        this.loadFavorites();
      });
    },
    clearFavorites () {
      let requestParams = {
        user_id: this.$auth.user.user_id
      };

      let headers = {
        'Content-Type': 'application/json'
      }

      this.loaded = false;

      portal.post(`favourites/remove-all`, requestParams, headers)
      .then((response) => {
        this.loadFavorites();
      });
    },
    getPriceFormatted (price, currencCurrency = null) {
      let currency = currencCurrency || this.currency;
      if (!price) {
        return 'P.O.A.';
      }

      return new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: currency
      }).format(price);
    }
  }
}
</script>
