<template>
  <main id="homepage">
    <header-slider
      class="hidden desktopSmall:block"
      :slides="page.acf.header_slider" />
    <bar-categories class="mt-8 tablet:mt-10 desktopSmall:mt-0" />
    <bar-news
      v-if="$i18n.locale === 'en'"
      class="hidden desktopSmall:block" />
    <bar-icons class="hidden desktopSmall:block" />
    <section
      v-if="products.length > 0"
      class="bar__categories hidden desktopSmall:block">
      <div class="container">
        <div class="flex flex-wrap">
          <div class="w-full px-4">
            <header-h4
              :content="$t('components.new_products.header')"
              casing="uppercase"
              align="center"
              mt="0"
              mb="4"
            />
          </div>
        </div>
        <div class="flex flex-wrap">
          <div
            v-for="product in products.slice(0, 3)"
            class="w-full tablet:w-1/3 px-4">
            <product-quick
              :data="product"
              :link="{
                name: 'product-product',
                params: {
                  product: $slugify(product.link)
                }
              }" />
          </div>

          <div class="mt-8 mb-4 text-center w-full">
            <button-primary
              :text="$t('components.new_products.all')"
              path="new-products" />
          </div>
        </div>
      </div>
    </section>
    <bar-usps />
  </main>
</template>

<script>
import BarUsps from '../components/bars/Usps'
import BarIcons from '../components/bars/Icons'
import HeaderSlider from '../components/headers/Slider'
import BarCategories from '../components/bars/Categories'
import BarNews from '../components/bars/News'
import ProductQuick from '../components/products/Quick'
import portal from '../service/portal'
import wordpress from '~/service/wordpress'

export default {
  name: 'Homepage',
  components: {
    ProductQuick,
    BarNews,
    BarCategories,
    HeaderSlider,
    BarIcons,
    BarUsps
  },
  head() {
    const title =
      this.page.yoast.yoast_wpseo_title === ''
        ? this.page.title + ' - Air Conditioning Parts - AP Air Ltd.'
        : this.page.yoast.yoast_wpseo_title
    const description =
      this.page.yoast.yoast_wpseo_metadesc === ''
        ? this.page.excerpt.replace(/<(?:.|\n)*?>/gm, '')
        : this.page.yoast.yoast_wpseo_metadesc

    return {
      title,
      titleTemplate: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        { property: 'og:locale', content: 'en_GB' },
        { property: 'og:type', content: 'page' },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        {
          property: 'og:url',
          content: 'https://www.apairltd.com' + this.$route.fullPath
        },
        { property: 'og:site_name', content: 'AP Air Ltd.' },
        {
          property: 'og:article:published_time',
          content: this.page.date
        },
        {
          property: 'og:article:modified_time',
          content: this.page.date_modified
        },
        {
          property: 'og:og:updated_time',
          content: this.page.date_modified
        }
      ]
    }
  },
  async asyncData({ app, store }) {
    const [page, products] = await Promise.all([
      wordpress.get(`page/${app.i18n.t('endpoints.homepage')}`),
      portal.get(`products/featured`)
    ])

    const newProducts = []
    for (let i = 0; i < products.data.length; i++) {
      const product = products.data[i]

      for (let j = 0; j < products.data[i].translations.length; j++) {
        if (products.data[i].translations[j].language.language_code === 'en') {
          product.fallback_product = products.data[i].translations[j]
        }
        if (
          products.data[i].translations[j].language.language_code ===
          store.state.language
        ) {
          product.product = products.data[i].translations[j]
        }
      }
      product.link =
        product.id +
        ' ' +
        product.sku +
        ' ' +
        (product.product ? product.product.name : product ? product.name : '')
      newProducts.push(product)
    }

    return {
      page: page.data,
      products: newProducts.slice(0, 3)
    }
  },
  mounted() {
    if (process.browser) {
      this.$gtag('event', 'your_event', {
        page_title: this.page.title,
        page_path: this.$route.fullPath
      })
    }
  }
}
</script>
