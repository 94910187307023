var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{attrs:{"id":"contact"}},[_c('bar-breadcrumbs',{attrs:{"data":_vm.breadcrumbs}}),_vm._v(" "),_c('section',{staticClass:"bg-gray-medium py-16"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"flex flex-wrap"},[_c('div',{staticClass:"w-full tablet:w-7/10 tablet:mx-auto text-center px-4"},[_c('header-h2',{attrs:{"content":_vm.page.title,"color":"black","casing":"uppercase","family":"header","align":"center","mb":"4"}}),_vm._v(" "),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('components.contact.text'))}})],1)]),_vm._v(" "),_c('div',{staticClass:"flex flex-wrap"},[_c('div',{staticClass:"w-full tablet:w-1/2 px-4"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.page.content)}})]),_vm._v(" "),_c('div',{staticClass:"w-full tablet:w-1/2 px-4"},[_c('div',{staticClass:"form mt-16"},[_c('form-main',{attrs:{"form":{
                showLabels: false,
                showPlaceholders: true,
                requiredText: '*',
                buttonType: 'primary',
                buttonText: _vm.$t('form.send'),
                fields: [
                  {
                    type: 'input',
                    subtype: 'text',
                    name: 'contact_name',
                    label: _vm.$t('form.name'),
                    required: true
                  },
                  {
                    type: 'input',
                    subtype: 'email',
                    name: 'contact_email',
                    label: _vm.$t('form.email'),
                    required: true
                  },
                  {
                    type: 'input',
                    subtype: 'tel',
                    name: 'contact_phone',
                    label: _vm.$t('form.phone'),
                    required: false
                  },
                  {
                    type: 'input',
                    subtype: 'text',
                    name: 'contact_subject',
                    label: _vm.$t('form.subject'),
                    required: false
                  },
                  {
                    type: 'textarea',
                    name: 'contact_message',
                    label: _vm.$t('form.message'),
                    value: _vm.formMessage,
                    required: false
                  },
                  {
                    type: 'select',
                    subtype: 'text',
                    baseLabel: _vm.$t('form.select_option'),
                    name: _vm.$t('components.account.form.discover_apair'),
                    label: _vm.$t('components.account.form.discover_apair'),
                    options: [
                      {
                        value: 'search_engine',
                        label: _vm.$t('components.account.form.search_engine')
                      },
                      {
                        value: 'friend',
                        label: _vm.$t('components.account.form.friend')
                      },
                      {
                        value: 'social_media',
                        label: _vm.$t('components.account.form.social_media')
                      },
                      {
                        value: 'magazine',
                        label: _vm.$t('components.account.form.magazine')
                      },
                      {
                        value: 'trade_show',
                        label: _vm.$t('components.account.form.trade_show')
                      },
                      {
                        value: 'contacted',
                        label: _vm.$t('components.account.form.contacted')
                      }
                    ],
                    required: false
                  }
                ]
              }}}),_vm._v(" "),(_vm.message !== '')?_c('div',{staticClass:"mt-4",domProps:{"innerHTML":_vm._s(_vm.message)}}):_vm._e()],1)])])])]),_vm._v(" "),_c('section',{attrs:{"id":"maps"}},[_c('iframe',{staticStyle:{"border":"0"},attrs:{"src":("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2471.479556781354!2d-2.3732906839096217!3d51.724263479671336!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4871a04446d99f75%3A0x7f4ab83fe9a3bae4!2sAP%20Air%20Europe%20Ltd!5e0!3m2!1s" + (_vm.$i18n.locale) + "!2s" + (_vm.$i18n.locale) + "!4v1619015798642!5m2!1s" + (_vm.$i18n.locale) + "!2s" + (_vm.$i18n.locale)),"width":"100%","height":"450","frameborder":"0","allowfullscreen":""}})]),_vm._v(" "),_c('bar-icons'),_vm._v(" "),_c('bar-usps')],1)}
var staticRenderFns = []

export { render, staticRenderFns }