<template>
  <main id="contact">
    <bar-breadcrumbs :data="breadcrumbs" />
    <section class="bg-gray-medium py-16">
      <div class="container">
        <div class="flex flex-wrap">
          <div class="w-full tablet:w-7/10 tablet:mx-auto text-center px-4">
            <header-h2
              :content="page.title"
              color="black"
              casing="uppercase"
              family="header"
              align="center"
              mb="4"
            />
            <p v-html="$t('components.contact.text')" />
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full tablet:w-1/2 px-4">
            <div v-html="page.content" />
          </div>
          <div class="w-full tablet:w-1/2 px-4">
            <div class="form mt-16">
              <form-main
                :form="{
                  showLabels: false,
                  showPlaceholders: true,
                  requiredText: '*',
                  buttonType: 'primary',
                  buttonText: $t('form.send'),
                  fields: [
                    {
                      type: 'input',
                      subtype: 'text',
                      name: 'contact_name',
                      label: $t('form.name'),
                      required: true
                    },
                    {
                      type: 'input',
                      subtype: 'email',
                      name: 'contact_email',
                      label: $t('form.email'),
                      required: true
                    },
                    {
                      type: 'input',
                      subtype: 'tel',
                      name: 'contact_phone',
                      label: $t('form.phone'),
                      required: false
                    },
                    {
                      type: 'input',
                      subtype: 'text',
                      name: 'contact_subject',
                      label: $t('form.subject'),
                      required: false
                    },
                    {
                      type: 'textarea',
                      name: 'contact_message',
                      label: $t('form.message'),
                      value: formMessage,
                      required: false
                    },
                    {
                      type: 'select',
                      subtype: 'text',
                      baseLabel: $t('form.select_option'),
                      name: $t('components.account.form.discover_apair'),
                      label: $t('components.account.form.discover_apair'),
                      options: [
                        {
                          value: 'search_engine',
                          label: $t('components.account.form.search_engine')
                        },
                        {
                          value: 'friend',
                          label: $t('components.account.form.friend')
                        },
                        {
                          value: 'social_media',
                          label: $t('components.account.form.social_media')
                        },
                        {
                          value: 'magazine',
                          label: $t('components.account.form.magazine')
                        },
                        {
                          value: 'trade_show',
                          label: $t('components.account.form.trade_show')
                        },
                        {
                          value: 'contacted',
                          label: $t('components.account.form.contacted')
                        }
                      ],
                      required: false
                    }
                  ]
                }"
              />
              <div v-if="message !== ''" v-html="message" class="mt-4" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="maps">
      <iframe
        :src="
          `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2471.479556781354!2d-2.3732906839096217!3d51.724263479671336!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4871a04446d99f75%3A0x7f4ab83fe9a3bae4!2sAP%20Air%20Europe%20Ltd!5e0!3m2!1s${$i18n.locale}!2s${$i18n.locale}!4v1619015798642!5m2!1s${$i18n.locale}!2s${$i18n.locale}`
        "
        width="100%"
        height="450"
        frameborder="0"
        style="border:0"
        allowfullscreen
      ></iframe>
    </section>
    <bar-icons />
    <bar-usps />
  </main>
</template>

<script>
import BarBreadcrumbs from '../components/bars/Breadcrumbs'
import BarIcons from '../components/bars/Icons'
import BarUsps from '../components/bars/Usps'
import wordpress from '~/service/wordpress'

export default {
  name: 'Contact',
  components: { BarUsps, BarIcons, BarBreadcrumbs },
  head() {
    const title =
      this.page.yoast.yoast_wpseo_title === ''
        ? this.page.title + ' - Air Conditioning Parts - AP Air Ltd.'
        : this.page.yoast.yoast_wpseo_title
    const description =
      this.page.yoast.yoast_wpseo_metadesc === ''
        ? this.page.excerpt.replace(/<(?:.|\n)*?>/gm, '')
        : this.page.yoast.yoast_wpseo_metadesc

    return {
      title,
      titleTemplate: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        { property: 'og:locale', content: 'en_GB' },
        { property: 'og:type', content: 'page' },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        {
          property: 'og:url',
          content: 'https://www.apairltd.com' + this.$route.fullPath
        },
        { property: 'og:site_name', content: 'AP Air Ltd.' },
        {
          property: 'og:article:published_time',
          content: this.page.date
        },
        {
          property: 'og:article:modified_time',
          content: this.page.date_modified
        },
        {
          property: 'og:og:updated_time',
          content: this.page.date_modified
        }
      ]
    }
  },
  data() {
    return {
      message: '',
      breadcrumbs: [
        {
          name: this.$t('menu.home'),
          url: 'index'
        },
        {
          name: this.$t('menu.contact'),
          url: 'contact'
        }
      ]
    }
  },
  async asyncData({ app, route }) {
    const [page] = await Promise.all([
      wordpress.get(`page/${app.i18n.t('endpoints.contact')}`)
    ])

    let formMessage = ''
    if (route.query.type !== undefined) {
      if (route.query.type === 'vehicle') {
        formMessage = app.i18n.t('form.type_vehicle')
      }
      if (route.query.type === 'part') {
        formMessage = app.i18n.t('form.type_part')
      }

      formMessage = formMessage + ' ' + route.query.automateid
    }

    return {
      page: page.data,
      formMessage
    }
  },
  mounted() {
    if (process.browser) {
      this.$gtag('event', 'your_event', {
        page_title: this.page.title,
        page_path: this.$route.fullPath
      })
    }
  },
  methods: {
    validateEmail(email) {
      // eslint-disable-next-line no-useless-escape
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },
    submitForm() {
      const params = new URLSearchParams()
      if (
        this.validateEmail(
          this.$el.getElementsByClassName('form_input')[1].value
        )
      ) {
        params.append(
          'contact_name',
          this.$el.getElementsByClassName('form_input')[0].value
        )
        params.append(
          'contact_email',
          this.$el.getElementsByClassName('form_input')[1].value
        )
        params.append(
          'contact_phone',
          this.$el.getElementsByClassName('form_input')[2].value
        )
        params.append(
          'contact_subject',
          this.$el.getElementsByClassName('form_input')[3].value
        )
        params.append(
          'contact_message',
          this.$el.getElementsByClassName('form_input')[4].value
        )
        params.append(
          'contact_knowfrom',
          this.$el.getElementsByClassName('form_input')[5].value
        )
        this.$axios
          .post(this.$t('endpoints.contact_form'), params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(() => {
            this.message = this.$t('form.thank_you')
          })
          .catch(() => {
            alert('BAD');
            this.message = this.$t('form.fail')
          })
      } else {
        this.message = this.$t('form.false_email')
      }
    }
  }
}
</script>
